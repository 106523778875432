import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { NOTIFICATION_GROUPS } from 'utils/constants';

const DateTh = styled.th`
  width: 11rem;
`;

const propTypes = {
  marketNotices: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        _linkType: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
        url: PropTypes.string,
      }),
      notice_date: PropTypes.string,
      notice_title: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

const defaultProps = {};

function MarketNotices({ marketNotices }) {
  const noticesByCategory = groupBy(marketNotices, 'type');

  return (
    <div>
      {NOTIFICATION_GROUPS.map(groupName => {
        const notices = sortBy(
          noticesByCategory[groupName] || [],
          'notice_date'
        ).reverse();

        return (
          <div>
            <h2
              id={groupName.replace(' ', '-').toLowerCase()}
              className='anchorOffset'
            >
              {groupName}
            </h2>
            {notices.length > 0 ? (
              <Table size='condensed'>
                <thead>
                  <tr>
                    <DateTh>Date</DateTh>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {notices.map(notice => (
                    <tr>
                      <td>
                        {moment(notice.notice_date).format('MMM DD YYYY')}
                      </td>
                      <td>
                        {notice.file ? (
                          <>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              size='lg'
                              className='text-red mr-3'
                            />
                            <a href={notice.file.url} target='_blank'>
                              {notice.notice_title}
                            </a>
                          </>
                        ) : (
                          <span>{notice.notice_title}</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>There are no {groupName} posted at this time.</p>
            )}
          </div>
        );
      })}
    </div>
  );
}

MarketNotices.propTypes = propTypes;
MarketNotices.defaultProps = defaultProps;

export default MarketNotices;
