import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import JumbotronBlock from '../components/JumbotronBlock';
import SideRailsMenu from '../components/SideRailsMenu';
import MarketNotices from 'components/MarketNotices';
import MainCol from '../components/PageBody/MainCol';
import NotificationsRailMenu from 'components/SideRailsMenu/NotificationsRailMenu';
import RichTextWithDictionary from 'components/RichTextWithDictionary';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function NotificationsPage({ data, errors }) {
  const doc = data.prismic.allNotifications_pages.edges.slice(0, 1).pop();
  if (!doc) {
    return null;
  }

  const docNode = doc.node;
  const {
    seo_title,
    seo_meta_description,
    page_title,
    page_content,
    _meta,
    market_notices,
  } = docNode;
  const pageName = page_title ? page_title[0].text : '';
  const { lang } = _meta;

  return (
    <Layout>
      <SEO
        title={seo_title || pageName}
        description={seo_meta_description}
        lang={lang}
      />
      <JumbotronBlock title={pageName} />
      <Row>
        <SideRailsMenu
          title={pageName}
          renderMenu={<NotificationsRailMenu />}
        />
        <MainCol lg={9} md={12}>
          {page_content && (
            <div>
              <RichTextWithDictionary render={page_content} />
            </div>
          )}
          <MarketNotices marketNotices={market_notices} />
        </MainCol>
      </Row>
    </Layout>
  );
}

export const query = graphql`
  query NotificationsPageQuery($uid: String) {
    prismic {
      allNotifications_pages(uid: $uid) {
        edges {
          node {
            _meta {
              lang
            }
            page_title
            page_content
            seo_meta_description
            seo_title
            market_notices {
              file {
                ... on PRISMIC__FileLink {
                  _linkType
                  name
                  size
                  url
                }
              }
              notice_date
              notice_title
              type
            }
          }
        }
      }
    }
  }
`;

NotificationsPage.propTypes = propTypes;
NotificationsPage.defaultProps = defaultProps;

export default NotificationsPage;
