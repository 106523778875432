import React from 'react';
import { NOTIFICATION_GROUPS } from 'utils/constants';
import SideRailContainer from './styled/SideRailContainer';
import RailMenuList from './styled/RailMenuList';
import MenuItem from './styled/MenuItem';
import MenuLink from './styled/MenuLink';

const propTypes = {};

const defaultProps = {};

function NotificationsRailMenu() {
  return (
    <SideRailContainer>
      <RailMenuList>
        {NOTIFICATION_GROUPS.map(groupName => (
          <MenuItem key={groupName}>
            <MenuLink href={`#${groupName.replace(' ', '-').toLowerCase()}`}>
              {groupName}
            </MenuLink>
          </MenuItem>
        ))}
      </RailMenuList>
    </SideRailContainer>
  );
}

NotificationsRailMenu.propTypes = propTypes;
NotificationsRailMenu.defaultProps = defaultProps;

export default NotificationsRailMenu;
